<template>
<div>
    <div class="s-hero swiper" >
        <div class="s-hero-head">
            <!-- <img src="../svg/hsy-logo.png" alt="" class="no-mobile"> -->
            <h3>{{item.small_description}}</h3>
            <h2>{{item.heading}}</h2>
        </div>
        <div class="s-hero-images">
            <div class="s-hero-wrapper" id="slider-wrapper">
                <a :href="'#/' + itm.link" class="s-hero-image" v-if="itm && itm.link" v-for="(itm, index) in item.data" :key="index">
                    <img :src="$store.state.file_url + itm.banner[0].src + '?alt=media'" alt="" /> 
                </a>
                <div class="s-hero-image" v-if="!itm.link" v-for="(itm, index) in item.data" :key="index"> 
                    <img :src="$store.state.file_url + itm.banner[0].src + '?alt=media'" alt="" /> 
                </div>
            </div>
        </div>
        <div class="s-hero-background" data-background="#f3f2f5"></div>
    </div>
</div>
</template>

<script>
export default {
    props: ["item"],
    data(){
        return{
            count: 0.2,
            interval: false
        }
    },
    created(){
        this.init()
    },
    methods:{
        init(){
            // this.count = 0.2
           this.interval = setInterval(() => {
                setTimeout(() => {
                    if(this.count < -2200){
                        this.count = 0
                    }
                    this.count -= 0.2
                    if(document.getElementById("slider-wrapper")){
                        document.getElementById("slider-wrapper").style.transform = 'translate3d('+this.count+'px, 0px, 0px)'
                    }
                },500)
            },12)
            
        },
    },
    destroyed(){
        clearInterval(this.interval)
    }
}
</script>

<style scoped>
.s-hero-head img{
    width: 10%;
    margin: auto;
}
.swiper{
    width: 100%;
}
.swiper h3{
    opacity: 0.8;
    font-weight: bold;
    font-size: 0.688vw;
    line-height: 0.688vw;
    margin-bottom: 3.75vw;
    letter-spacing: 0.188vw;
    text-transform: uppercase;
    font-family: 'Aktiv Grotesk';
}
.swiper h2{
    width: 80%;
    margin: 0 auto;
    font-weight: 300;
    font-size: 5.938vw;
    line-height: 5.619vw;
    letter-spacing: -0.231vw;
    text-transform: uppercase;
    font-family: 'Saol Display';
}
.swiper h2 em{
    text-transform: lowercase;
}
.s-hero-images{
    margin-top: 15px;
    width: 100%;
    overflow: hidden;
    transform: translateX(0);
}
@media only screen and (max-width: 580px){
    .swiper h3{
    font-size: 2.778vw;
    line-height: 4.167vw;
    margin-bottom: 5.556vw;
    letter-spacing: 0.75vw;
    }
    .swiper h2{
    margin: 0 calc(100vw / 12 * 1);
    font-size: 11.111vw;
    line-height: 10vw;
    letter-spacing: -0.419vw;
    }
    .s-hero-head img{
        width: 35%;
    }
}
</style>